import { createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { PROPS_AUTHEN, PROPS_PROFILE, PROPS_NICKNAME } from '../types';

const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncLogin = createAsyncThunk(
    "auth/post",
    async (authen: PROPS_AUTHEN) => {
        // const res = await axios.post(`${apiUrl}authen/jwt/create`, authen, {
        const res = await axios.post(`${apiUrl}api/authenv2/`, authen, {
                headers: {
                "Content-Type": "application/json",
            },
        });
        return res.data;
    }
)

export const fetchAsyncRegister = createAsyncThunk (
    "auth/register",
    async (auth: PROPS_AUTHEN) => {
        // const res = await axios.post(`${apiUrl}api/register/`, auth, {
        const res = await axios.post(`${apiUrl}api/registerv2/`, auth, {
                headers: {
                "Content-Type": "application/json",              
            },
        });
    return res.data
    }
)

export const fetchAsyncCreateProf = createAsyncThunk (
    "profile/post",
    async (nickName: PROPS_NICKNAME) => {
        // const res = await axios.post(`${apiUrl}api/profile/`, nickName, {
        const res = await axios.post(`${apiUrl}api/profilev2/`, nickName, {
                headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.localJWT}`
            },
        });
        return res.data;
    }
)

export const fetchAsyncUpdateProf = createAsyncThunk (
    "profile/put",
    async (profile: PROPS_PROFILE) => {
        const uploadData = new FormData();
        uploadData.append ("nickName", profile.nickName);
        profile.img && uploadData.append("img", profile.img, profile.img.name); // If profile img exists, append img data.
        const res = await axios.put(
            // `${apiUrl}api/profile/${profile.id}/`,
            `${apiUrl}api/profilev2/`,
            uploadData,
            {
                headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.localJWT}`,
                },
            }
        );
        return res.data
    }
);

export const fetchAsyncGetMyProf = createAsyncThunk(
    "profile/get",
    async () => {
        // const res = await axios.get(`${apiUrl}api/myprofile/`,{
        const res = await axios.get(`${apiUrl}api/myprofilev2/`,{
                headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },  
        });
        // return res.data[0]
        return res.data
    }
);

export const fetchAsyncGetProfs = createAsyncThunk(
    "profiles/get",
    async () => {
        // const res = await axios.get(`${apiUrl}api/profile/`, {
        const res = await axios.get(`${apiUrl}api/profilev2/`, {
                headers: {
                Authorization: `JWT ${localStorage.localJWT}`
            },
        });
        return res.data;
    }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState:{
    openSignIn: true,
    openSignUp: false,
    openProfile: false,
    isLoadingAuth: false,
    myprofile: {
        id: 0,
        nickName: "",
        userProfile: 0,
        created_on: "",
        img: "",
    },
    profiles: [
        {
            id: 0,
            nickName: "",
            userProfile: 0,
            created_on: "",
            img: "",
        },
    ],
  },
  reducers: {
    fetchCredStart(state) {
        state.isLoadingAuth = true;
    },
    fetchCredEnd(state) {
        state.isLoadingAuth = false;
    },
    setOpenSignIn(state) {
        state.openSignIn = true;
    },
    resetOpenSignIn(state) {
        state.openSignIn = false;
    },
    setOpenSignUp(state) {
        state.openSignUp = true;
    },
    resetOpenSignUp(state) {
        state.openSignUp = false;
    },
    setOpenProfile(state) {
        state.openProfile = true;
    },
    resetOpenProfile(state) {
        state.openProfile = false;
    },
    editNickName(state, action) {
        state.myprofile.nickName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncLogin.fulfilled, (state, action) => {
        localStorage.setItem("localJWT", action.payload.access);
    });
    builder.addCase(fetchAsyncCreateProf.fulfilled, (state, action) => {
        state.myprofile = action.payload;
    });
    builder.addCase(fetchAsyncGetMyProf.fulfilled, (state, action) => {
        state.myprofile = action.payload;
    });
    builder.addCase(fetchAsyncGetProfs.fulfilled, (state, action) => {
        state.profiles = action.payload;
    });
    builder.addCase(fetchAsyncUpdateProf.fulfilled, (state, action) => {
        state.myprofile = action.payload;
        state.profiles = state.profiles.map((prof) =>
            prof.id === action.payload.id ? action.payload : prof // If prof.id equal to action.payload.id, then update profile by action.payload. Not equal, no update. This is Ternary Operator behavior of "?" and combination of ":".
        );
    });
  },
});

export const { 
    fetchCredStart, 
    fetchCredEnd, 
    setOpenSignIn, 
    resetOpenSignIn, 
    setOpenSignUp, 
    resetOpenSignUp,
    setOpenProfile, 
    resetOpenProfile, 
    editNickName,
    } = authSlice.actions;

export const selectIsLoadingAuth = (state: RootState) => state.auth.isLoadingAuth;
export const selectOpenSignIn = (state: RootState) => state.auth.openSignIn;
export const selectOpenSignUp = (state: RootState) => state.auth.openSignUp;
export const selectOpenProfile = (state: RootState) => state.auth.openProfile;
export const selectProfile = (state: RootState) => state.auth.myprofile;
export const selectProfiles = (state: RootState) => state.auth.profiles;

export default authSlice.reducer;
