import { createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { PROPS_NEWPOST, PROPS_LIKED, PROPS_COMMENT } from '../types';
import imageCompression from "browser-image-compression";

const apiUrlPost = `${process.env.REACT_APP_DEV_API_URL}api/postv2/`;
const apiUrlComment = `${process.env.REACT_APP_DEV_API_URL}api/commentv2/`;

export const fetchAsyncGetPosts = createAsyncThunk("post/get", async() => {
    const res = await axios.get(apiUrlPost, {
        headers: {
            Authorization: `JWT ${localStorage.localJWT}`
        },
    });
    return res.data;
});

export const fetchAsyncNewPost = createAsyncThunk(
    "post/post",
    async (newPost: PROPS_NEWPOST) => {
        const uploadData = new FormData();
        uploadData.append("title", newPost.title); 
        const compressedFile = await compressImage(newPost.img);
        newPost.img && uploadData.append("img", compressedFile, newPost.img.name);
        const res = await axios.post(apiUrlPost, uploadData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncPatchLiked = createAsyncThunk(
    "post/patch",
    async (liked: PROPS_LIKED) => {
        const currentLiked = liked.current;
        const uploadData = new FormData();

        let isOverlapped = false;
        currentLiked.forEach((current) => {
            if (current === liked.new) {
                isOverlapped = true;
            }
            else {
                uploadData.append("liked", String(current));
            }
        });

        if (!isOverlapped) {
            uploadData.append("liked", String(liked.new));
        } else if (currentLiked.length === 1) {
            uploadData.append("id", String(liked.id));  
            uploadData.append("liked", String(liked.current));
            const res = await axios.patch(`${apiUrlPost}`, uploadData, {
                    headers: {
                    Authorization: `JWT ${localStorage.localJWT}`,
                },
            });
            return res.data;
        }

        uploadData.append("id", String(liked.id));  
        const res = await axios.patch(`${apiUrlPost}`, uploadData, {
                headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetComments = createAsyncThunk(
    "comment/get",
    async () => {
        const res = await axios.get(apiUrlComment, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncPostComment = createAsyncThunk (
    "comment/post",
    async (comment: PROPS_COMMENT) => {
        const res = await axios.post(apiUrlComment, comment, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const postSlice = createSlice({
    name: 'post',
    initialState:{
        isLoadingPost: false,
        openNewPost: false,
        posts: [
            {
                id: 0,
                title: "",
                userPost: 0,
                created_on: "",
                img: "",
                liked: [0],
            },
        ],
        comments: [
            {
                id: 0,
                text: "",
                userComment: 0,
                post: 0,
            },
        ],
    },
    reducers: {
        fetchPostStart(state) {
            state.isLoadingPost = true;
        },
        fetchPostEnd(state) {
            state.isLoadingPost = false;
        },
        setOpenNewPost(state) {
            state.openNewPost = true;
        },
        resetOpenNewPost(state) {
            state.openNewPost = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetPosts.fulfilled, (state, action) => {
            return {
                ...state,
                posts: action.payload,
            };
        });
        builder.addCase(fetchAsyncNewPost.fulfilled, (state, action) => {
            return {
                ...state,
                posts: [...state.posts, action.payload],
            };
        });
        builder.addCase(fetchAsyncGetComments.fulfilled, (state, action) => {
            return {
                ...state,
                comments: action.payload,
            };
        });
        builder.addCase(fetchAsyncPostComment.fulfilled, (state, action) => {
            return {
                ...state,
                comments: [...state.comments, action.payload],
            };
        });
        builder.addCase(fetchAsyncPatchLiked.fulfilled, (state, action) => {
            return {
                ...state,
                posts: state.posts.map((post) =>
                    post.id === action.payload.id ? action.payload : post
            ),
            };
        });
    },
});

export const { 
    fetchPostStart,
    fetchPostEnd,
    setOpenNewPost,
    resetOpenNewPost,
} = postSlice.actions;

export const selectIsLoadingPost = (state: RootState) => 
    state.post.isLoadingPost;
export const selectOpenNewPost = (state: RootState) => state.post.openNewPost;
export const selectPosts = (state: RootState) => state.post.posts;
export const selectComments = (state: RootState) => state.post.comments;

export type compressImageType = {
    maxSizeMB: number;
    useWebWorker: boolean;
    initialQuality: number;
  };
  
  // optionsは必要に応じて調整する
  // https://github.com/Donaldcwl/browser-image-compression#api
  export const compressImage = async (
    // file: File| null,
    file: any,
    options: compressImageType = {
      maxSizeMB: 20,
      useWebWorker: true,
      initialQuality: 0.85,
    }
  ): Promise<File> => {
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (err) {
      return Promise.reject(new Error(`compress failed: ${err}`));
    }
  };

export default postSlice.reducer;
